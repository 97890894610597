<template>
  <section>
    <h3>Basic Informations</h3>
    <div class="boxinside">
      <v-form
        ref="companyBasicForm"
        v-model="valid"
        lazy-validation
        class="form-section"
      >
        <div class="fullwidth">
          <label class="mr-btm">Company Logo</label>
          
          <img v-if="oldimage" :src="url + oldimage" alt="" />
          <image-uploader
            :preview="true"
            :className="['fileinput', { 'fileinput--loaded': hasImage }]"
            capture="environment"
            :debug="1"
            doNotResize="gif"
            :autoRotate="true"
            outputFormat="file"
            @input="setImage"
            class="imagebox"
          >
            <label for="fileInput" slot="upload-label">
              <figure>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="32"
                  height="32"
                  viewBox="0 0 32 32"
                >
                  <path
                    class="path1"
                    d="M9.5 19c0 3.59 2.91 6.5 6.5 6.5s6.5-2.91 6.5-6.5-2.91-6.5-6.5-6.5-6.5 2.91-6.5 6.5zM30 8h-7c-0.5-2-1-4-3-4h-8c-2 0-2.5 2-3 4h-7c-1.1 0-2 0.9-2 2v18c0 1.1 0.9 2 2 2h28c1.1 0 2-0.9 2-2v-18c0-1.1-0.9-2-2-2zM16 27.875c-4.902 0-8.875-3.973-8.875-8.875s3.973-8.875 8.875-8.875c4.902 0 8.875 3.973 8.875 8.875s-3.973 8.875-8.875 8.875zM30 14h-4v-2h4v2z"
                  ></path>
                </svg>
              </figure>
              <span class="upload-caption">{{
                hasImage ? "Replace" : "Click to upload"
              }}</span>
            </label>
          </image-uploader>
        </div>

        <div class="fullwidth">
          <label class="mr-btm">Company Banner</label>
          <img v-if="oldBanner" :src="url + oldBanner" alt="" />
          <image-uploader
            :preview="true"
            :className="['fileinput', { 'fileinput--loaded': hasImage1 }]"
            capture="environment1"
            :debug="1"
            doNotResize="gif"
            :autoRotate="true"
            outputFormat="file"
            @input="setImage1"
            class="imagebox"
            id="fileInput1"
          >
            <label for="fileInput1" slot="upload-label">
              <figure>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="32"
                  height="32"
                  viewBox="0 0 32 32"
                >
                  <path
                    class="path1"
                    d="M9.5 19c0 3.59 2.91 6.5 6.5 6.5s6.5-2.91 6.5-6.5-2.91-6.5-6.5-6.5-6.5 2.91-6.5 6.5zM30 8h-7c-0.5-2-1-4-3-4h-8c-2 0-2.5 2-3 4h-7c-1.1 0-2 0.9-2 2v18c0 1.1 0.9 2 2 2h28c1.1 0 2-0.9 2-2v-18c0-1.1-0.9-2-2-2zM16 27.875c-4.902 0-8.875-3.973-8.875-8.875s3.973-8.875 8.875-8.875c4.902 0 8.875 3.973 8.875 8.875s-3.973 8.875-8.875 8.875zM30 14h-4v-2h4v2z"
                  ></path>
                </svg>
              </figure>
              <span class="upload-caption">{{
                hasImage1 ? "Replace" : "Click to upload"
              }}</span>
            </label>
          </image-uploader>
        </div>
        <div class="fullwidth">
          <label class="mr-btm">Company Name</label>
          <v-text-field
            v-model="companyname"
            outlined
            label="Company Name"
            class="mr-btm-minus"
          ></v-text-field>
        </div>

        <div class="fullwidth" v-if="allCategory.results">
          <label class="mr-btm">Industry</label>
          <v-select
            v-model="category"
            :items="allCategory.results.list"
            item-text="title"
            item-value="title"
            label="Industry"
            outlined
          ></v-select>
        </div>

        <div class="fullwidth">
          <label class="mr-btm">Ownership</label>
          <v-select
            v-model="ownership"
            :items="ownerships"
            label="Ownership"
            outlined
          ></v-select>
        </div>

        <div class="fullwidth">
          <label class="mr-btm">Size of Organization</label>
          <v-select
            v-model="sizeEmployee"
            :items="sizeEmployees"
            label="Size of Organization"
            outlined
          ></v-select>
        </div>
        <div class="fullwidth">
          <label class="mr-btm">Website</label>
          <v-text-field
            v-model="website"
            outlined
            label="Website"
            class="mr-btm-minus"
          ></v-text-field>
        </div>
        <div class="fullwidth mr-btm">
          <label class="mr-btm">About company</label>
          <quill-editor
            ref="myQuillEditor"
            v-model="description"
            :options="editorOption"
            @blur="onEditorBlur($event)"
            @focus="onEditorFocus($event)"
            @ready="onEditorReady($event)"
          />
        </div>
        <div class="fullwidth">
          <label class="mr-btm">Address</label>
          <v-text-field
            v-model="address"
            outlined
            label="Address"
            class="mr-btm-minus"
          ></v-text-field>
        </div>

        <div
          class="fullwidth"
          v-for="(contact, index) in contactNumbers"
          :key="index"
        >
          <label class="mr-btm">Contact Numbers</label>
          <div class="removesection">
            <v-text-field
              v-model="contact.phone"
              outlined
              label="Phone Number"
              class="mr-btm-minus"
            ></v-text-field>
            <div
              class="iconbox removebutton"
              @click="removeContactNumber(index)"
              v-if="index > 0"
            >
              <v-icon> mdi-minus-circle-outline </v-icon> Remove
            </div>
          </div>
        </div>
        <v-btn class="addmore spacebottom" @click="addContactNumber"
          ><v-icon> mdi-plus-circle-outline </v-icon> Add Another Contact
          Number</v-btn
        >
        <div>
          <v-btn class="addmore" @click="saveCompany">Next</v-btn>
        </div>
      </v-form>
    </div>
  </section>
</template>

<script>
import ImageUploader from "vue-image-upload-resize";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "BasicInformationCompany",
  data: () => ({
    editorOption: {},
    valid: true,
    companyname: "",
    ownership: "",
    url: "https://app.necojobs.com.np",
    ownerships: ["Government", "Private", "Public", "Non-profit"],
    sizeEmployees: [
      "1 - 10 employees",
      "10 - 50 employees",
      "50 - 100 employees",
      "100 - 200 employees",
      "200 - 500 employees",
      "500 - 1000 employees",
      "1000+ employees",
      "Confidential",
    ],
    sizeEmployee: "",
    website: "",
    description: "",
    category: "",
    address: "",
    contactNumbers: [{ phone: "" }],
    oldimage: "",
    oldBanner: "",
    hasImage: false,
    hasImage1: false,
    image: null,
    image1: null,
    msg: "Vue Image Upload and Resize Demo",
    id: null,
  }),
  components: { ImageUploader },
  methods: {
    ...mapActions([
      "fetchCategory",
      "createBaiscInfo",
      "getBasicInformation",
      "getSingleCompanyUser",
    ]),
    saveCompany() {
      let fd = new FormData();

      fd.append("companyname", this.companyname);
      fd.append("ownership", this.ownership);
      fd.append("sizeEmployee", this.sizeEmployee);
      fd.append("website", this.website);
      fd.append("description", this.description);
      fd.append("category", this.category);
      fd.append("address", this.address);
      fd.append("image", this.image);
      fd.append("banner", this.image1);
      fd.append("userHandle", this.$route.params.slug);
      fd.append("fileType", "images");
      fd.append("imageFile", "companies");
      fd.append("contactNumbers", JSON.stringify(this.contactNumbers));

      if (this.id != null) {
        fd.append("id", this.id);
      }

      this.createBaiscInfo(fd);
      this.$emit("success", "contact_person");
    },
    onEditorBlur(quill) {
      console.log("editor blur!", quill);
    },
    onEditorFocus(quill) {
      console.log("editor focus!", quill);
    },
    onEditorReady(quill) {
      console.log("editor ready!", quill);
    },
    onEditorChange({ quill, html, text }) {
      console.log("editor change!", quill, html, text);
      this.content = html;
    },
    addContactNumber() {
      this.contactNumbers.push({
        phone: "",
      });
    },
    removeContactNumber(index) {
      this.contactNumbers.splice(index, 1);
    },

    setImage: function (output) {
      this.hasImage = true;
      this.image = output;
    },
    setImage1: function (output) {
      this.hasImage1 = true;
      this.image1 = output;
    },
  },
  computed: {
    ...mapGetters([
      "allCategory",
      "allBasicInfoCompany",
      "allSingleCompany",
      "allLogin",
    ]),
  },
  async created() {
    this.fetchCategory();
    await this.getSingleCompanyUser({ id: this.$route.params.slug });

    this.companyname = this.allSingleCompany.results.fullname;
    await this.getBasicInformation(this.$route.params.slug);
    if (this.allBasicInfoCompany.results != null) {
      this.companyname = this.allBasicInfoCompany.results.companyname;
      this.category = this.allBasicInfoCompany.results.category;
      this.ownership = this.allBasicInfoCompany.results.ownership;
      this.sizeEmployee = this.allBasicInfoCompany.results.sizeEmployee;
      this.website = this.allBasicInfoCompany.results.website;
      this.description = this.allBasicInfoCompany.results.description;
      this.address = this.allBasicInfoCompany.results.address;
      this.contactNumbers = this.allBasicInfoCompany.results.phone;
      this.oldimage = this.allBasicInfoCompany.results.imageUrl;
      this.oldBanner = this.allBasicInfoCompany.results.bannerUrl;
      this.id = this.allBasicInfoCompany.results._id;
    }
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/variables.scss";
@import "@/assets/scss/form.scss";
img {
  width: 200px;
}
</style>
