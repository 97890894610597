<template>
  <section>
    <v-form
      ref="socialAccountForm"
      v-model="valid"
      lazy-validation
      class="form-section"
    >
      <h3>Contact Person</h3>
      <div
        class="fullwidth"
        v-for="(contactPerson, index) in contactPersons"
        :key="index"
      >
        <div class="repeated-formbox">
          <div class="formbox">
            <label class="mr-btm">Name</label>
            <div class="removesection">
              <v-text-field
                v-model="contactPerson.name"
                label="Name"
                outlined
              ></v-text-field>
            </div>
            <label class="mr-btm">Email</label>
            <div class="removesection">
              <v-text-field
                v-model="contactPerson.email"
                label="Email"
                outlined
              ></v-text-field>
            </div>
            <label class="mr-btm">Designation</label>
            <div class="removesection">
              <v-text-field
                v-model="contactPerson.designation"
                label="Designation"
                outlined
              ></v-text-field>
            </div>
            <label class="mr-btm">Phone 1</label>
            <div class="removesection">
              <v-text-field
                v-model="contactPerson.phone1"
                label="Phone 1"
                outlined
              ></v-text-field>
            </div>
            <label class="mr-btm">Phone 2</label>
            <div class="removesection">
              <v-text-field
                v-model="contactPerson.phone2"
                label="Phone 2"
                outlined
              ></v-text-field>
            </div>
            <label class="mr-btm">Phone 3</label>
            <div class="removesection">
              <v-text-field
                v-model="contactPerson.phone3"
                label="Phone 3"
                outlined
              ></v-text-field>
            </div>
          </div>
          <div
            class="iconbox removebutton"
            @click="removeContactPerson(index)"
            v-if="index > 0"
          >
            <v-icon> mdi-minus-circle-outline </v-icon> Remove
          </div>
        </div>
      </div>
      <v-btn class="addmore spacebottom" @click="addContactPerson"
        ><v-icon> mdi-plus-circle-outline </v-icon> Add Contact Person</v-btn
      >
      <div>
        <v-btn class="addmore" @click="saveContactPerson">Next</v-btn>
      </div>
    </v-form>
  </section>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  name: "ContactPerson",
  data: () => ({
    valid: true,
    contactPersons: [
      {
        name: "",
        email: "",
        designation: "",
        phone1: "",
        phon2: "",
        phone3: "",
      },
    ],
  }),
  methods: {
    ...mapActions(["createContactPerson", "getContactPerson"]),
    saveContactPerson() {
      this.createContactPerson(this.contactPersons);
      this.$emit("success", "social_account");
    },
    addContactPerson() {
      this.contactPersons.push({
        name: "",
        email: "",
        designation: "",
        phone1: "",
        phon2: "",
        phone3: "",
        userHandle: this.$route.params.slug,
      });
    },
    removeContactPerson(index) {
      this.contactPersons.splice(index, 1);
    },
  },
  computed: {
    ...mapGetters(["allContactPerson"]),
  },
  async created() {
    await this.getContactPerson(this.$route.params.slug);
    if (this.allContactPerson.results != null) {
      this.contactPersons = [];
      this.allContactPerson.results.forEach((element) => {
        this.contactPersons.push({
          name: element.name,
          email: element.email,
          designation: element.designation,
          phone1: element.phone1,
          phon2: element.phone2,
          phone3: element.phone3,
          userHandle: this.$route.params.slug,
          id: element._id,
        });
      });
    }
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/variables.scss";
@import "@/assets/scss/form.scss";
</style>
