<template>
  <section>
    <v-form
      ref="socialAccountForm"
      v-model="valid"
      lazy-validation
      class="form-section"
    >
      <h3>Social Account</h3>
      <div
        class="fullwidth"
        v-for="(sociallink, index) in socialLinks"
        :key="index"
      >
        <div class="repeated-formbox">
          <div class="formbox">
            <label class="mr-btm">Social Media Name</label>
            <div class="removesection">
              <v-select
                v-model="sociallink.socialMediaName"
                :items="socialmedias"
                label="Social Media Name"
                outlined
              ></v-select>
            </div>
            <label class="mr-btm">Account Link</label>
            <div class="removesection">
              <v-text-field
                v-model="sociallink.accountLink"
                outlined
                label="Account Link"
                class="mr-btm-minus"
              ></v-text-field>
            </div>
          </div>
          <div
            class="iconbox removebutton"
            @click="removeSocial(index)"
            v-if="index > 0"
          >
            <v-icon> mdi-minus-circle-outline </v-icon> Remove
          </div>
        </div>
      </div>
      <v-btn class="addmore spacebottom" @click="addSocial"
        ><v-icon> mdi-plus-circle-outline </v-icon> Add Social Account</v-btn
      >
      <div>
        <v-btn class="addmore" @click="socialAccountSave">Save</v-btn>
      </div>
    </v-form>
  </section>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  name: "SocialAccountCompany",
  data: () => ({
    valid: true,
    socialLinks: [{ socialMediaName: "", accountLink: "" }],
    socialmedias: ["facebook", "twitter", "linkedin", "pinterest", "instagram"],
  }),
  methods: {
    ...mapActions(["createSocialAccount", "getSocialAccount"]),
    async socialAccountSave() {
      await this.createSocialAccount(this.socialLinks);
      this.$router.push("/dashboard/companylist");
    },
    addSocial() {
      this.socialLinks.push({
        socialMediaName: "",
        accountLink: "",
        resume_number: this.$route.params.slug,
      });
    },
    removeSocial(index) {
      this.socialLinks.splice(index, 1);
    },
  },
  computed: {
    ...mapGetters(["allSocialAccount"]),
  },
  async created() {
    if (localStorage.getItem("token") == undefined) {
      this.$router.push("/");
    } else {
      if (this.$route.params.slug) {
        this.socialLinks = [];
        await this.getSocialAccount(this.$route.params.slug);
        if (this.allSocialAccount.results != null) {
          this.allSocialAccount.results.forEach((element) => {
            this.socialLinks.push({
              socialMediaName: element.socialMediaName,
              accountLink: element.accountLink,
              id: element._id,
              resume_number: this.$route.params.slug,
            });
          });
        }
      }
    }
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/variables.scss";
@import "@/assets/scss/form.scss";
</style>
