<template>
  <section>
    <v-row>
      <v-col md="3">
        <ul class="leftmenu">
          <li
            v-for="(data, index) in menulists"
            :key="index"
            :class="{ activeMenu: selected == data.slug }"
          >
            <div @click="chooseMenu(data.slug)">
              <v-icon class="icon">{{ data.icon }}</v-icon> {{ data.title }}
            </div>
          </li>
        </ul>
      </v-col>
      <v-col md="9">
        <v-row>
          <div v-if="selected == 'basic_information'" class="fullwidth mainbox">
            <BasicInformationCompany @success="next" />
          </div>
          <div v-if="selected == 'contact_person'" class="fullwidth">
            <ContactPerson @success="next" />
          </div>

          <div v-if="selected == 'social_account'" class="fullwidth">
            <SocialAccountCompany />
          </div>
        </v-row>
      </v-col>
    </v-row>
  </section>
</template>

<script>
import BasicInformationCompany from "@/components/Company/BasicInformation";
import SocialAccountCompany from "@/components/Company/SocialAccountCompany";
import ContactPerson from "@/components/Company/ContactPerson";

export default {
  name: "AddCompany",
  components: { BasicInformationCompany, SocialAccountCompany, ContactPerson },
  data: () => ({
    menulists: [
      {
        title: "Basic Informations",
        slug: "basic_information",
        icon: "mdi-newspaper-variant-outline",
      },
      {
        title: "Contact Person",
        slug: "contact_person",
        icon: "mdi-school",
      },
      {
        title: "Social Account",
        slug: "social_account",
        icon: "mdi-share-variant",
      },
    ],
    about: "",
    selected: "basic_information",
  }),
  methods: {
    chooseMenu(data) {
      this.selected = data;
    },
     next(e) {
      // console.log("hey");
      this.chooseMenu(e);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/variables.scss";
@import "@/assets/scss/form.scss";
ul{
  list-style: none;
}
</style>
